
import Vue from 'vue';
import { VForm } from 'vuetify/types/custom';
import intl from '@/utils/intl';
import validators from '@/utils/validators';
import Http, { HttpError } from '@/utils/Http';

export default Vue.extend({
  mixins: [validators],
  data() {
    return {
      forgotForm: false,
      loading: false,
      alertType: 'error',
      alertMessage: '',
      username: null as string | null,
      userErrorMessages: []
    }
  },
  computed: {
    buttonText(): string {
      return intl.t('views.forgotPassword.button');
    },
    userFieldLabel(): string {
      return intl.t('views.login.username');
    },
    backToButtonText(): string {
      return intl.t('general.backTo', intl.t('views.login.title'));
    }
  },
  methods: {
    async submit(): Promise<void> {
      if ((this.$refs.forgotForm as VForm).validate()) {
        this.loading = true;
        this.alertMessage = '';
        const data = {
          username: this.username,
          url: `${location.origin}/reset-password`
        };
        try {
          await (new Http()).post('/v1/account/forgot-password', JSON.stringify(data));
          (this.$refs.forgotForm as VForm).reset();
          this.alertType = 'success';
          this.alertMessage = intl.t('views.forgotPassword.successMessage');
        } catch (error) {
          const err = (error as HttpError).response?.data.error;
          this.alertType = 'error';
          if (err) {
            if ('username' in err) {
              this.userErrorMessages = err.username;
            }
            this.alertMessage = err.message;
          } else {
            this.alertMessage = intl.t('general.noConnection');
          }
        }
        this.loading = false;
      }
    }
  }
});
